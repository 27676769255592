/**
 * @file General javascript utilities.
 */

/**
 * Given an object, remove all members that are nully, and traverse into member objects (and arrays), removing
 * nully members (of sub-objects).
 * @param {object} object
 * @return {object} The stripped object (it is stripped in place).
 * @memberof IrUtils
 */
function deepStripNullies(object, _unused, _cycleChecker) {
	if (!_cycleChecker) {
		_cycleChecker = new Set();
	}

	if (!isNully(object) && ('object' === typeof (object))) {
		if (Array.isArray(object)) {
			object.forEach((el) => {
				if ('object' === typeof (el)) {
					if (!_cycleChecker.has(el)) {
						_cycleChecker.add(el);
						deepStripNullies(el, null, _cycleChecker);
					}
				}
			});
		} else {
			Object.keys(object).forEach((k) => {
				if (isNully(object[k])) { delete object[k]; }
				else if ('object' === typeof (object[k])) {
					const el = object[k];
					if (!_cycleChecker.has(el)) {
						_cycleChecker.add(el);
						deepStripNullies(el, null, _cycleChecker);
					}
				}
			});
		}
	}

	return object;
}

/**
 * Returns the first argument that is not undefined.
 * @param {...*} _args
 * @return {any}
 * @memberof IrUtils
 */
function firstDefined(_args) {
	for (let i = 0; i < arguments.length; ++i) {
		if ('undefined' !== typeof(arguments[i])) {
			return arguments[i];
		}
	}
	return null;
}

/**
 * Returns true if a string variable is undefined, null, or ''.
 * @param {string=} string
 * @return {boolean}
 * @memberof IrUtils
 */
function isNullyOrEmptyString(string) {
	return (isNully(string) || ('' === string));
}

/**
 * Is val undefined or null.
 * @param {any=} val
 * @return {boolean}
 * @memberof IrUtils
 */
function isNully(val) {
	return (('undefined' === typeof (val)) || (null === val));
}

/**
 * Is the javascript type of x 'number'?
 * @param {any} x
 * @return {boolean}
 * @memberof IrUtils
 */
function isNumber(x) {
	return typeof (x) === 'number';
}

function isValidGuid(guid) {
	return !!(guid && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid));
}

function normalizeContentId(cId) {
	if (isNully(cId)) { return null; }
	return `${cId}`.replaceAll('-', '').toLowerCase();
}

function normalizeDecimalId(val) {
	return val ? ('' + val).toLowerCase().replaceAll(/[^0-9]/g, '') : null;
}

function normalizeGuid(val) {
	const tmp = val ? ('' + val).toLowerCase().replaceAll(/[^0-9a-f]/g, '') : null;
	return (32 === tmp?.length) ? tmp : null;
}


/**
 * Given an object, remove all members, in place, that are nully, at the top level (shallow).
 * @param {object} object
 * @return {object} The stripped object (it is stripped in place).
 * @memberof IrUtils
 */
function shallowStripNullies(object) {
	!isNully(object) && ('object' === typeof (object)) && !Array.isArray(object) &&
		Object.keys(object).forEach((k) => isNully(object[k]) && delete object[k]);
	return object;
}

/**
 * Returns the provided string variable or empty string if nully.
 * @param {string=} string
 * @return {string}
 * @memberof IrUtils
 */
function toEmptyStringIfNully(string) {
	return isNully(string) ? '' : string;
}

/**
 * General javascript utilities on vanilla javascript objects/types.
 * @namespace
 */
const IrUtils = {
	deepStripNullies: deepStripNullies,
	firstDefined: firstDefined,
	isNully: isNully,
	isNullyOrEmptyString: isNullyOrEmptyString,
	isNumber: isNumber,
	isValidGuid: isValidGuid,
	normalizeContentId: normalizeContentId,
	normalizeDecimalId: normalizeDecimalId,
	normalizeGuid: normalizeGuid,
	shallowStripNullies: shallowStripNullies,
	toEmptyStringIfNully: toEmptyStringIfNully
};

export { deepStripNullies, firstDefined, isNully, isNullyOrEmptyString, isNumber, isValidGuid, normalizeContentId, normalizeDecimalId, normalizeGuid, shallowStripNullies, toEmptyStringIfNully, IrUtils }
