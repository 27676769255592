import { ContentId } from '../models/content-id.es12.js'

/**
 * Model for the viewer page.
 * WIP wrapping and migrating functionality from legacy code.
 */
class ViewerModel {
	constructor(options) {
		this._api = options.api;
		this._content = {};
		this._isUserIdentificationComplete = false;
		this._project = null;
		this._projectGuid = null;
		this._projectId = null;
		this._propertyUnitDynamicData = new Map();
		this._roomId = options.roomId;
		this._session = options.session;
		this._settings = options.settings;

		this._aiEnabledPerProject = ('project' === options?.settings?.ai?.enabled);
		this.aiEnabled = ![false, 'project'].includes(options?.settings?.ai?.enabled);

		this.userProjectAdminGuids = [];
		this.userHasInteracted = false;
		this._clearContent();
	}

	get aiSettings() {
		return this._aiSettings;
	}

	/** Numeric ID. */
	get chatId() {
		return this._roomId;
	}

	get telemetryChannelId() {
		const telemetryRoomIdFormat = this._settings?.Telemetry?.RoomIdFormat ?? '{0}';
		return telemetryRoomIdFormat.replace('{0}', this._roomId);
	}

	get chatLeadCaptureDialogMarkdown() {
		return this._project?.ExtraJson?.ChatLeadCapture?.DialogMarkdown;
	}

	get contentId() {
		return new ContentId({ id: this._content.Id, guid: this._content.PublicId, type: this._content.Type });
	}

	get isChatLeadCaptureEnabled() {
		return !!(this._project?.ExtraJson?.ChatLeadCapture?.Enabled);
	}

	get isLoggedIn() {
		return this._session.isLoggedIn;
	}

	get isRequestAccessButtonEnabled() {
		return !!(this._project?.ExtraJson?.RequestAccess?.Enabled);
	}

	get isSplashScreenEnabled() {
		return !!(this._project?.ExtraJson?.SplashScreen?.Enabled);
	}

	get session() {
		return this._session;
	}

	get splashScreenFields() {
		const visible = new Map();
		const required = new Map();
		for (const [key, value] of Object.entries(this._project?.ExtraJson?.SplashScreen?.InputFields || {})) {
			if (value.Visible) {
				const lcKey = key.toLowerCase()
				visible.set(lcKey, true);
				if (value.Required) {
					required.set(lcKey, true);
				}
			}
		}

		return {
			visible: visible,
			required: required
		}
	}

	get unitTypeFilter() {
		// return a function that takes a dynamicDataPropertyUnit as an argument,
		// and returns true if it matches the project's filter value
		return (u) => {
			const filter = this._project?.ExtraJson?.UnitTypeFilter; // Single string
			const flags = u?.Flags || []; // Array of strings
			if (!filter) { // default is !affordable
				return !flags.includes('affordable');
			} else if ('all' === filter) {
				return true;
			} else {
				return flags.includes(filter);
			}
		};
	}

	get isUserIdentificationComplete() {
		return this._isUserIdentificationComplete;
	}
	set isUserIdentificationComplete(val) {
		return this._isUserIdentificationComplete = !!val;
	}

	get projectGuid() {
		return this._projectGuid;
	}

	get projectId() {
		return this._projectId;
	}

	get requestAccessButtonText() {
		return this._project?.ExtraJson?.RequestAccess?.ButtonText;
	}

	get requestAccessDialogMarkdown() {
		return this._project?.ExtraJson?.RequestAccess?.DialogMarkdown;
	}

	/**
	 * The index in the listing-specific content item list (of the currently selected item).
	 */
	get selectedListingSelectedContentItemIndex() {
		if (this.selectedContentItemIndex < 0) {
			return -1;
		}
		const ci = this.contentItems[this.selectedContentItemIndex];
		return this.selectedListingContentItems.findIndex((el) => el === ci);
	}

	/**
	 * Retrieve a content item object by ID
	 * @param {object} contentItemId type=ListingContentItem|ListingUrl, id=numeric id
	 */
	getContentItem(contentItemId) {
		if (contentItemId && this.contentItems) {
			return this.contentItems.find((ci) => (ci.contentReferenceType === contentItemId.type) && (ci.contentReferenceId === contentItemId.id));
		}
		return null;
	}

	getDynamicListingData(listing) {
		return this._propertyUnitDynamicData.get(+listing?.PropertyUnitId);
	}

	getListing(listingId) {
		if ((null === listingId) || !this._project || !this._project.Listings) { return null; }
		const marketableListing = this._project.Listings.find((l) => l.Id == listingId);
		return (marketableListing ? marketableListing : this.listingUdgMap.get(listingId));
	}

	getNextContentItem() {
		let i = this.selectedContentItemIndex;
		if (i < 0) {
			return null;
		}
		if (++i >= this.contentItems.length) {
			i = 0;
		}
		return this.contentItems[i];
	}

	getPreviousContentItem() {
		let i = this.selectedContentItemIndex;
		if (i < 0) {
			return null;
		}
		if (--i < 0) {
			i = this.contentItems.length - 1;
		}
		return this.contentItems[i];
	}

	getSelectedContentItem() {
		return this.contentItems[this.selectedContentItemIndex];
	}

	getSelectedListing() {
		return this.getListing(this.selectedListingId);
	}

	isProjectLoaded() {
		return !!this._projectId;
	}

	/**
	 * Second step in updating this model.
	 * Takes the legacy-style object maps and generates new-style representations.
	 * @returns
	 */
	refreshFromLegacyModel() {
		return this._setObjectMaps()
			._setContentItemList()
			._setSelectedListingContentItems()
			._updateUdgListings()
			._setProjectAiSettings();
	}

	/**
	 * First call when a new project is loaded. This must be done before the legacy code
	 * renders buildings because it sets display order for items
	 * @param {any} content viewercontent object (project)
	 * @returns
	 */
	setContentBeforeLegacyModelUpdate(content) {
		this._clearContent();
		this._projectGuid = this._projectId = null;
		this._content = content;
		if (content?.Project) {
			this._content.Type = 'campaign';
			this._project = content.Project;
			this._projectGuid = content.Project.PublicId;
			this._projectId = content.Project.Id;
		} else {
			this._content.Type = 'project';
			this._project = content;
			this._projectGuid = content.PublicId;
			this._projectId = content.Id;
		}
		try {
			this._project.ExtraJson = JSON.parse(this._project.ExtraJson)
		} catch (ex) {
			this._project.ExtraJson = {};
			console.error('While parsing project ExtraJson', ex, Date.now() * .001);
		}
		if (this._aiEnabledPerProject && this._project.ExtraJson?.AIEnabled) {
			this.aiEnabled = true;
		}
		if (this.aiEnabled) {
			try { window.page?.hal9000?.handleProjectLoaded(!!content.Project ? content.Project : content); } catch (_) { }
		}
		this._setPropertyUnitDynamicData();
		return this._applyDisplayOrder();
	}

	_setProjectAiSettings() {
		this._aiSettings = this._project?.ExtraJson?.AI || {};
		const buildings = Array.from(this.buildings.values());
		if ((1 === buildings.length) && ('res' === buildings[0].PropertyTypeCode?.toLowerCase())) {
			if (!this._aiSettings?.Prompt1 && !this._aiSettings?.Prompt2 && !this._aiSettings?.Prompt3) {
				this._aiSettings.Prompt1 = '"Tour the property."';
				this._aiSettings.Prompt2 = '"Show me nearby restaurants."';
				this._aiSettings.Prompt3 = '"Where can I walk my dog?"';
			}
		}
		return this;
	}

	setSelectedContentItem(contentItem) {
		if (!contentItem) {
			this.selectedContentItemIndex = -1;
			return this;
		}
		const i = this.contentItems.findIndex((el) => (el.contentReferenceId === contentItem.contentReferenceId) && (el.contentReferenceType === contentItem.contentReferenceType));
		if (i >= 0) {
			this.selectedContentItemIndex = i;
			this.setSelectedListingId(this.contentItems[i].listingId);
		}
		return this;
	}

	setSelectedListingId(listingId) {
		if (listingId !== this.selectedListingId) {
			this.selectedListingId = listingId;
			this._setSelectedListingContentItems();
			if (this.selectedListingContentItems.length > 0) {
				this.setSelectedContentItem(this.selectedListingContentItems[0]);
			}
		}
		return this;
	}

	/**
	 * Apply display order to features (floorplans) and amenities.
	 * There is an EXPLICIT order of things in the DisplayOrderJson field (may be incorrect),
	 * and a DEFAULT order in which listings appear in the viewercontent query result (project).
	 * Handle situations where the explicit order may refer to things that don't exist.
	 */
	_applyDisplayOrder() {
		const explicitOrder = (this._content?.DisplayOrderJson) ?
			JSON.parse(this._content.DisplayOrderJson) :
			{
				Properties: []
			};
		const defaultOrder = this._getDefaultPropertyOrder();
		const actualOrder = this._getActualPropertyOrder(explicitOrder, defaultOrder);

		// Set default project listing id.
		// NOTE TODO: This requires the first listing for project to be an amenity or marketable (for now - UDG doesn't work anyways).
		if (explicitOrder?.ProjectDefaultListingId &&
			this._content?.Listings?.find((l) => explicitOrder.ProjectDefaultListingId === l.Id))
		{
			actualOrder.ProjectDefaultListingId = explicitOrder.ProjectDefaultListingId;
		}
		if (!actualOrder.ProjectDefaultListingId) {
			actualOrder.ProjectDefaultListingId = this._content?.Listings?.length ? this._content.Listings[0]?.Id : null;
		}

		EveryScape.SyncV2.ContentDisplayOrder = this.displayOrder = actualOrder;
		return this;
	}

	/**
	 * Apply the amenity and marketable orders, and set default listing ID for project.
	 * @param {any} explicitOrder {Id: propertyId, DefaultListingId:, Listings: {Amenities: [ids...], Marketable: [ids...]}}
	 * @param {any} actualOrder {Id: propertyId, DefaultListingId:, Listings: {Amenities: [ids...], Marketable: [ids...]}}
	 */
	_applyExplicitListingOrder(explicitOrder, actualOrder) {
		this._applyExplicitListingOrderHelper(explicitOrder.Listings?.Amenities, actualOrder.Listings.Amenities);
		this._applyExplicitListingOrderHelper(explicitOrder.Listings?.Marketable, actualOrder.Listings.Marketable);

		// NOTE TODO: This requires the first listing for property to be an amenity or marketable (for now - UDG doesn't work anyways)
		if (explicitOrder.DefaultListingId) {
			const listingExistsInProperty = (actualOrder.Listings.Marketable.indexOf(explicitOrder.DefaultListingId) >= 0)
				|| (actualOrder.Listings.Amenities.indexOf(explicitOrder.DefaultListingId) >= 0);
			if (listingExistsInProperty) {
				actualOrder.DefaultListingId = explicitOrder.DefaultListingId;
			}
		}

		return this;
	}

	_applyExplicitListingOrderHelper(explicit, actual) {
		// Get the explicit listing IDs in reverse order and then, in that order
		// move them to the front (if those IDs exist in actual).
		const explicitListingIds = explicit?.reverse() || [];
		explicitListingIds.forEach((id) => {
			const i = actual.indexOf(id);
			if (i > 0) {
				actual.unshift(actual.splice(i, 1)[0]); // Move it to the front.
			}
		});
		return this;
	}

	_clearContent() {
		this._aiSettings = {};
		this.buildings = new Map();
		this.contentItems = []; // All of them for all buildings/listings.
		this.displayOrder = { Properties: [] };
		this.explicitDisplayOrder = false;
		this.listingUdgMap = new Map(); // Listings that are UDG listings, by id.
		this.selectedContentItemIndex = -1; // Index in contentItems (whole list).
		this.selectedListingId = null;
		this.selectedListingContentItems = []; // Only the items for the currently selected listing.
		return this;
	}

	_compareContentItems(a, b) {
		if (!a && !b) { return 0; }
		else if (!a) { return 1; }
		else if (!b) { return -1; }

		if (!isNaN(a.sequence)) {
			if (!isNaN(b.sequence)) {
				if (a.sequence < b.sequence) return -1;
				if (a.sequence > b.sequence) return 1;
			} else {
				return -1;
			}
		} else if (b.sequence) {
			return 1;
		}

		if (a.contentReferenceType) {
			const objTypeComp = a.contentReferenceType.localeCompare(b.contentReferenceType);
			if (objTypeComp != 0) return objTypeComp;
		} else if (b.contentReferenceType) {
			return -1
		}

		if (a.contentReferenceId < b.contentReferenceId) return -1;
		if (a.contentReferenceId > b.contentReferenceId) return 1;

		return 0;
	}

	/**
	 * Combine the explicitly requested order and order in the
	 * viewercontent query in a safe manner.
	 * @param {any} explicitOrder
	 * @param {any} defaultOrder Modified by this call.
	 * @returns
	 */
	_getActualPropertyOrder(explicitOrder, defaultOrder /* modified */) {
		const actualOrder = {
			Properties: []
		};

		// Loop through the explicitly set display order and merge it with the
		// viewercontent-query-returned content's default order.
		explicitOrder?.Properties?.forEach((explicitPropertyOrder) => {
			const i = defaultOrder.Properties.findIndex((el) => explicitPropertyOrder.Id === el.Id);
			if (i >= 0) {
				const defaultPropertyOrder = defaultOrder.Properties.splice(i, 1)[0];
				actualOrder.Properties.push(defaultPropertyOrder);
				this._applyExplicitListingOrder(explicitPropertyOrder, defaultPropertyOrder);
			}
		});

		// Those that exist in the content response, but not in DisplayOrderJson.
		actualOrder.Properties = actualOrder.Properties.concat(defaultOrder.Properties);

		return actualOrder;
	}

	/**
	 * Get the order of properties and listings in the order they are in the returned project JSON.
	 * @returns {Properties: [{ Id, DefaultListingId:, Listings: { Amenities: [listingId...], Marketable: [listingId...]}}] }
	 */
	_getDefaultPropertyOrder() {
		const contentOrder = []; // of properties
		this._content?.Listings?.forEach((listing) => {
			const propertyId = listing?.PropertyUnit?.Property?.Id;
			const listingId = listing?.Id;
			if (propertyId && listingId) {
				const isAmenity = this._isListingAnAmenity(listing);
				let propertyOrder = contentOrder.find((el) => propertyId === el.Id);
				if (!propertyOrder) {
					propertyOrder = {
						Id: propertyId,
						DefaultListingId: listingId,
						Listings: { Amenities: [], Marketable: [] }
					};
					contentOrder.push(propertyOrder);
				}
				const listingIdOrder = isAmenity ? propertyOrder.Listings.Amenities : propertyOrder.Listings.Marketable;
				if (!listingIdOrder.includes(listing.Id)) {
					listingIdOrder.push(listing.Id);
				}
			}
		});
		return { Properties: contentOrder };
	}

	_getListingContentItems(listingId) {
		const listing = this.getListing(listingId);
		let listingContentItems = [];
		((listing && listing.ListingContentItems) || []).forEach((item) => {
			listingContentItems.push({
				contentPath: getContentPathFromLCI(item),
				contentReferenceId: item.Id,
				contentReferenceType: 'ListingContentItem',
				description: item.Description,
				displayOrder: item.DisplaySequence,
				item: item,
				listingId: listingId,
				name: item.Name,
				position: defaultPositionJsonToArray(item.DefaultPositionJson),
				thumbnailUrl: item.ThumbnailUrl,
				sequence: item.DisplaySequence
			});
		});
		((listing && listing.ListingUrls) || []).forEach((listingUrl) => {
			listingContentItems.push({
				contentPath: 'overlay://default',
				contentReferenceId: listingUrl.Id,
				contentReferenceType: 'ListingUrl',
				description: listingUrl.Description,
				displayOrder: listingUrl.DisplayOrder,
				item: listingUrl,
				listingId: listingId,
				name: listingUrl.Label,
				position: [],
				thumbnailUrl: listingUrl.ThumbnailUrl,
				url: listingUrl.Url,
				sequence: listingUrl.DisplayOrder
			});
		});
		listingContentItems.sort(this._compareContentItems); // Per-listing sort.
		return listingContentItems;
	}

	_isListingAnAmenity(listing) {
		return ('amenity' === listing?.PropertyUnit?.UnitType?.toLowerCase());
	}

	/**
	 * Create a huge long list of all content items using the order for buildings, going through marketable
	 * listings, then amenities, then neighborhood listings.
	 * @returns {ViewerModel}
	 */
	_setContentItemList() {
		this.contentItems = [];
		(this.displayOrder?.Properties || []).forEach((displayOrderForProperty) => {
			const building = this.buildings.get(displayOrderForProperty.Id);
			const orderedListingIds = (displayOrderForProperty.Listings?.Amenities || [])
				.concat(displayOrderForProperty.Listings?.Marketable || []);
			(building?.UserDefinedGroups || []).forEach((udg) => {
				(udg?.UserDefinedGroup?.ListingUserDefinedGroups || [])
					.forEach((ludg) => {
						orderedListingIds.push(ludg.ListingId);
					});
			});
			orderedListingIds.forEach((listingId) => {
				this.contentItems = this.contentItems.concat(this._getListingContentItems(listingId));
			});
		});
		if (this.selectedContentItemIndex >= this.contentItems.length) {
			this.selectedContentItemIndex = -1;
		}

		return this;
	}

	/**
	 * Reload the object maps (possibly after edit-mode updates) from the legacy maps.
	 * @returns
	 */
	_setObjectMaps() {
		this.buildings = EveryScape.SyncV2.AllBuildings;
		this.listingUdgMap = new Map();
		for (let [_, building] of this.buildings) {
			(building.UserDefinedGroups || []).forEach((udg) => {
				(udg?.UserDefinedGroup?.ListingUserDefinedGroups || []).forEach((ludg) => {
					this.listingUdgMap.set(ludg.Listing.Id, ludg.Listing);
				});
			});
		}
		return this;
	}

	/**
	 * Set this._propertyUnitDynamicData to a Map of property unit ID to dynamic data (hott/realpage) about the property unit.
	 * The data should look like:
	 * {
	 *   "RealPageSubdomainId": "8747783", // Subdomain used in outlinks to realpage lease workflow
	 *   "SourcePropertyId": "4852902", // ID in realpage
	 *   "PropertyId": 6, // Infinityy property ID
	 *   "PropertyUnits": [
	 *     {
	 *       "Id": 79, // Infinityy propertyUnit ID
	 *       "Units": [{
	 *         "SourceUnitId": "52", // ID in realpage
	 *         "UnitNumber": "350",
	 *         "FloorNumber": "3",
	 *         "Deposit": 0.0,
	 *         "Price": 2650.0,
	 *         "Available": "3/8/2023"
	 *       }]
	 *     }
	 *   ]
	 * }
	 */
	_setPropertyUnitDynamicData() {
		this._propertyUnitDynamicData = new Map();
		const unitTypeFilter = this.unitTypeFilter;
		if (this._content.PropertyDynamicData) {
			this._content.PropertyDynamicData.forEach((dd) => {
				try {
					const propertyIn = JSON.parse(dd.DynamicDataJson);
					propertyIn.PropertyUnits.forEach((propertyUnitIn) => {
						const propertyUnitId = propertyUnitIn.Id;
						let propertyUnit = this._propertyUnitDynamicData.get(propertyUnitId);
						if (!propertyUnit) {
							propertyUnit = {
								id: +propertyUnitId,
								property: {
									id: propertyIn.PropertyId,
									SourcePropertyId: propertyIn.SourcePropertyId,
									RealPageSubdomainId: propertyIn.RealPageSubdomainId
								},
								units: [],
								urlOverride: propertyUnitIn.UrlOverride
							};
							this._propertyUnitDynamicData.set(propertyUnitId, propertyUnit);
						}
						propertyUnitIn.Units.forEach((unitIn) => {
							if (unitTypeFilter(unitIn)) {
								propertyUnit.units.push($.extend({}, unitIn, { propertyUnit: propertyUnit }));
							}
						});
					});
				} catch (ex) {
					console.error('Error parsing property dynamic data.', ex);
				}
			});
		}
	}

	/**
	 * Update the listing-specific list of content items (features) based on the currently selected listing.
	 * @returns
	 */
	_setSelectedListingContentItems() {
		this.selectedListingContentItems = this.contentItems.filter((el) => el.listingId == this.selectedListingId);
		return this;
	}

	/**
	 * Update the UDG listings to refer to their UDGs and buildings.
	 */
	_updateUdgListings() {
		this.buildings.forEach((building) => {
			(building.UserDefinedGroups || []).forEach((udg) => {
				if (udg.UserDefinedGroup && udg.UserDefinedGroup.ListingUserDefinedGroups) {
					udg.UserDefinedGroup.ListingUserDefinedGroups.forEach((ludg) => {
						if (ludg.Listing) {
							ludg.Listing.UserDefinedGroupId = ludg.UserDefinedGroupId;
							ludg.Listing.PropertyId = building.Id;
						}
					});
				}
			});
		});
		return this;
	}
}

function defaultPositionJsonToArray(json) {
	let obj = null;
	if (json) { obj = JSON.parse(json); }
	if (!obj || ('object' !== typeof (obj))) { obj = {}; }

	// format: [ 1.23, 4.56, 7.89 ]
	if (Array.isArray(obj)) { return obj.map(parseFloat); }

	// format: {"hlookat":0,"vlookat":0,"fov":1} (EveryScape Panorama)
	if (isNumeric(obj.hlookat) && isNumeric(obj.vlookat) && isNumeric(obj.fov)) {
		return [obj.hlookat, obj.vlookat, obj.fov].map(parseFloat);
	}

	// format: {'page': 1, 'zoom': 1, 'x': 0, 'y': 0 } (PDF)
	if (isNumeric(obj.page) && isNumeric(obj.zoom) && isNumeric(obj.x) && isNumeric(obj.y)) {
		return [obj.page, obj.zoom, obj.x, obj.y].map(parseFloat);
	}

	// format: {'time': 0, 'playing': 1, 'x': 0, 'y': 0 } (Video)
	if (isNumeric(obj.time) && isNumeric(obj.playing) && isNumeric(obj.x) && isNumeric(obj.y)) {
		return [obj.time, obj.playing, obj.x, obj.y].map(parseFloat);
	}

	// format: {'px': 0, 'py': 0, 'pz': 0, 'rx': 0, 'ry': 0, 'zoom': 0 } (Matterport)
	if (isNumeric(obj.px) && isNumeric(obj.py) && isNumeric(obj.pz) && isNumeric(obj.rx) && isNumeric(obj.ry) && isNumeric(obj.zoom)) {
		return [obj.px, obj.py, obj.pz, obj.rx, obj.ry, obj.zoom].map(parseFloat);
	}

	// format: {'x': 0, 'y': 0, 'z': 1 } (2D Content)
	if (isNumeric(obj.x) && isNumeric(obj.y) && isNumeric(obj.z)) {
		return [obj.x, obj.y, obj.z].map(parseFloat);
	}

	return []; // Invalid format.
}

function getContentPathFromLCI(lci) {
	if (lci.ContentSetItem) {
		return lci.ContentSetItem.ContentPath;
	}
	switch (lci.ContentTypeCode) {
		case 'panorama':
		case 'tour':
		case 'script':
		case 'videoscape':
		case 'poi':
			return 'everyscape://' + lci.ContentTypeCode + '/' + lci.ContentId;
		case 'image':
			prefix = 'olio://';
			idparts = lci.ContentId.split('/');
			return 'olio://container/' + idparts[0] + '/file/' + idparts[1];
		default:
			return null;
	}
}

function isNumeric(val) {
	return ((null !== val) && !isNaN(+val));
}

export { ViewerModel }
