import { WebkitSpeechToText as STTDriver } from './webkit-speech-to-text.mjs'

class SpeechToText {
	constructor(options) {
		options ??= {};
		this._beepOnUrl = '/Content/Audio/mic-on.mp3';
		this._beepOffUrl = '/Content/Audio/mic-off.mp3';
		this._transcriptionOutputElement = options.transcriptionOutputElement || document.createElement('DIV');
		this._transcriptionDoneCallback = options.transcriptionDoneCallback || function (_text, _error) { return false };
		this._driver = new STTDriver({
			debug: true,
			pauseWaitMs: options.pauseWaitMs,
			initialPauseWaitMs: options.initialPauseWaitMs
		});
		this._driver.onUpdate = (text) => {
			this._updateResult(text);
		}
		this._driver.onEnd = (text, error) => {
			console.debug('[stt]', 'Playing end beep.', Date.now() * .001);
			this._beepOffElement.currentTime = 0;
			this._beepOffElement.play();

			this._transcriptionDoneCallback(text, error);
		};
		this._driver.onAudioStart = () => {
			console.debug('[stt]', 'Playing start beep.', Date.now() * .001);
			this._beepOnElement.currentTime = 0;
			this._beepOnElement.play();
		};
		this._beepOnElement = document.createElement('audio');
		this._beepOnElement.src = this._beepOnUrl;
		this._beepOffElement = document.createElement('audio');
		this._beepOffElement.src = this._beepOffUrl;

		this.onUpdate = (_text) => { return false; };
	}

	cancel() {
		this._driver.cancel();
		return this;
	}

	get isSupported() {
		return this._driver.isSupported;
	}

	_updateResult(text) {
		$(this._transcriptionOutputElement).textOrVal(text);
		this.onUpdate(text);
		return this;
	}

	record() {
		this._recording = true;
		this._driver.start();
		return this;
	}

	stop() {
		this._recording = false;
		this._driver.stop();
		return this;
	}

	get transcriptionOutputElement() {
		return this._transcriptionOutputElement;
	}

	set transcriptionOutputElement(element) {
		return this._transcriptionOutputElement = element;
	}

	get transcriptionDoneCallback() {
		return this._transcriptionDoneCallback;
	}

	set transcriptionDoneCallback(fn) {
		this._transcriptionDoneCallback = fn;
	}
}

export { SpeechToText };
