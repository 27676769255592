import $ from 'jquery';
import { default as Cookies } from 'js-cookie';
import { isValidGuid } from './utils/utils.es13.js';
import { MulticastEventHandler } from './utils/multicast-event-handler.es13';

const COOKIE_NAMES = {
	IDENTITY_TOKEN: 'InfinityyIdentityToken', // Guid,NumericTimestamp
	AUTH_TOKEN: 'InfinityySecurityToken'
};

const IDENTITY_LOADED_EVENT_TYPE = 'identity-loaded'

/**
 * Infinityy Room Session
 */
class IrSession {
	/**
	 * @param {string=} options.settings.CookieDomain
	 */
	constructor(options) {
		this._api = options?.api;
		this._cookieDomain = options?.settings?.CookieDomain;
		this._cookieDomainArg = this._cookieDomain ? { domain: this._cookieDomain } : {};
		this._identity = null;
		this._eventHandler = new MulticastEventHandler();
	}

	get authToken() {
		return Cookies.get(COOKIE_NAMES.AUTH_TOKEN);
	}

	get contact() {
		return this._identity.ContactInformation;
	}

	get clientToken() {
		return {
			AuthToken: this.authToken,
			IdentityToken: this.identityToken
		};
	}

	get clientTokenV4() {
		const token = {};
		if (this.authToken) { token.authToken = this.authToken; }
		const it = this.identityToken;
		if (it?.Guid) {
			token.identityToken = {
				guid: it.Guid,
				timestamp: it.Timestamp
			};
		}
		return token;
	}

	get identityId() {
		return this._identity?.Id;
	}

	get identityToken() {
		let token = Cookies.get(COOKIE_NAMES.IDENTITY_TOKEN);
		if (!token) {
			return null;
		}
		let [guid, timestamp] = token.split(',');
		if (!isValidGuid(guid)) {
			guid = null;
		}
		timestamp = +timestamp || null;
		if (!guid) {
			return null;
		}
		const identityToken = { Guid: guid };
		if (timestamp) {
			identityToken.Timestamp = timestamp;
		}
		return identityToken;
	}

	set identityToken(identityToken) {
		if (isValidGuid(identityToken?.Guid)) {
			const timestamp = +identityToken.Timestamp || '';
			Cookies.set(COOKIE_NAMES.IDENTITY_TOKEN, `${identityToken.Guid},${timestamp}`,
				$.extend({ expires: 3650 }, this._cookieDomainArg));
		} else {
			Cookies.remove(COOKIE_NAMES.IDENTITY_TOKEN);
		}
		return this.identityToken;
	}

	get isIdentified() {
		return !!this._identity?.Id;
	}

	get isInternal() {
		return !!this._identity?.IsInternal;
	}

	get isLoggedIn() {
		return !!this.authToken;
	}

	get projectCount() {
		const onlyUnique = (value, index, array) => { return array.indexOf(value) === index };
		return (this._identity?.Rights ?? []).map(r => r.ProjectId).filter(onlyUnique).length;
	}

	clearAuthToken() {
		Cookies.remove(COOKIE_NAMES.AUTH_TOKEN);
		return this;
	}

	fromNewStyleIdentityToken(it) {
		return it?.guid ? { Guid: it.guid, Timestamp: it.timestamp } : null;
	}

	setIdentity(data) {
		// Convert V4 formats to in memory formats
		const contactInfo = data.identityContact ? {
			DisplayName: data.identityContact.name,
			EmailAddress: data.identityContact.email
		} : data.ContactInformation;

		if (data.rights) {
			data.Rights = data.rights.map((r) => {
				return {
					ProjectId: r.projectId,
					CanEdit: r.canEdit
				}
			});
		}

		this._identity = {
			Id: data.identityId ?? data.IdentityId,
			IsInternal: data.isInternal ?? data.IsInternal,
			ContactInformation: contactInfo,
			Rights: data.Rights || [],
			AvatarPhotoUrl: data.AvatarPhotoUrl
		};
		this.identityToken = this.fromNewStyleIdentityToken(data.identityToken) ?? data.IdentityToken;
		if (this.identityToken?.Guid && this.identityToken?.Timestamp) {
			this.clearAuthToken();
		}
		this._eventHandler.trigger(IDENTITY_LOADED_EVENT_TYPE);
		return this;
	}

	getIdentityFast() {
		return new Promise((resolve, reject) => {
			this._api.getOrCreateIdentity(this.clientToken)
				.then((data) => {
					this.setIdentity(data);
					resolve(this);
				})
				.catch((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				});
		});
	}

	/**
	 * Returns the rights for the current identity associated with the provided projectId
	 * @param {Number} projectId
	 * @returns {Array.<ContentRight>}
	 */
	getRights(projectId) {
		return this._identity?.Rights?.filter((right) => { return right.ProjectId == projectId }) || [];
	}

	/**
	 * Register an event handler for when the identity has been loaded
	 * @param {function} handler
	 * @param {object=} options
	 * @returns {IrSession}
	 */
	onIdentityLoaded(handler, options) {
		this._eventHandler.on(IDENTITY_LOADED_EVENT_TYPE, handler, options);
		return this;
	}

	updateContact(contact, promo) {
		this._identity.ContactInformation = $.extend({ isPending: true }, contact);
		return new Promise((resolve, reject) => {
			this._api.updateContact({
				AuthToken: this.authToken,
				IdentityToken: this.identityToken,
				ContactInformation: contact,
				PromoCode: promo
			}).catch((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).then((data) => {
				this._identity.ContactInformation = data.ContactInformation;
				if (data.IdentityId) this._identity.Id = data.IdentityId;
				resolve(this);
			});
		});
	}
}

export { IrSession }
