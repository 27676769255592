import $ from 'jquery';
import { normalizeDecimalId, normalizeGuid } from './utils.es13.js'

const CONTENT_STRUCTURE = {
	campaignGuid: null,
	campaignId: null,
	coverPhotoUrl: null,
	listingId: null,
	name: null,
	projectId: null,
	projectGuid: null,
	projectTypeId: null,
	projectSubType: null
};

class PreloadContent {
	constructor(session, gaHandler) {
		this._preloadContent = null;
		this._rootUrl = `${window.location.protocol}//${window.location.host}`
		this._session = session;
		this._gaHandler = gaHandler;
	}

	recordPageView(content) {
		this._preloadContent = $.extend(CONTENT_STRUCTURE, content);
		const pageName = content.name || document.title;
		this._doRecordPageViewWhenReady(pageName);
		document.title = pageName;
		this._preloadContent.name = pageName;
	}

	getNormalizedLocation() {
		const content = this._preloadContent;
		if (!content) { return null; }

		if (content.project?.guid) {
			let location = `${this._rootUrl}/view/project/${normalizeGuid(content.project.guid)}`;
			if (content.campaign?.guid) {
				location += `/campaign/${normalizeGuid(content.campaign.guid)}`;
			}
			return location;
		} else if (content.listing?.id) {
			return `${this._rootUrl}/view/listing/${normalizeDecimalId(content.listing.id)}`;
		} else {
			return null;
		}
	}

	get content() {
		return this._preloadContent;
	}

	_doRecordPageView(name) {
		let location = this.getNormalizedLocation();
		if (this._session.isInternal) {
			location = `${location}/internal`;
			name = `${name} (internal traffic)`;
		} else {
			const rights = this._session.getRights(this._preloadContent?.project?.id);
			if (rights.length > 0) {
				location = `${location}/agent`
				name = `${name} (agent traffic)`;
			}
		}
		return this._gaHandler.recordPageView(name, location);
	}

	_doRecordPageViewWhenReady(name) {
		this._session.onIdentityLoaded(() => this._doRecordPageView(name));
		if (this._session.isIdentified) {
			this._doRecordPageView(name);
		}
	}
}

export { PreloadContent }
