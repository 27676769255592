import { MulticastEventHandler } from '../utils/multicast-event-handler.es13.js';

const SEND_MESSAGE_EVENT_TYPE = 'send-message';

class ChatView {
	/**
	 * @param {object=} options
	 * @param {object}
	 * @param {CrmDashboardChatModel=} options.model
	 */
	constructor(options) {
		this._alerter = options.alerter;
		this._eventHandler = new MulticastEventHandler();
		this._model = options.model;
		this._shim = options?.shim;
	}

	alertNewChatMessage(chatMessageShim) {
		this._displayChatMessage(chatMessageShim);
		return this;
	}

	initialize() {
		this._initializeHtmlElements();
		this._model
			.onRoomChanged(() => { this._handleRoomChanged(); }, { synchronous: true });
		this._$toggleChatButton.unbind('click').bind('click', () => { this._shim?.toggleChatControl && this._shim.toggleChatControl() });
		return this._initializeSendChatForm();
	}

	/**
	 * Register an event handler for when the user is sending a message.
	 * @param {function} handler
	 * @param {object=} options
	 * @param {boolean=} [options.synchronous=false]
	 * @returns {CrmDashboardChatView}
	 */
	onSendMessage(handler, options) {
		this._eventHandler.on(SEND_MESSAGE_EVENT_TYPE, handler, options);
		return this;
	}

	_handleRoomChanged() {
		this._$chatContainer.toggleClass('invisible', !this._model.roomId);
		return this;
	}

	get _isChatControlHidden() {
		return $('body').hasClass('chatHidden');
	}

	_displayChatMessage(chatMessageShim) {
		this._alerter.displayChatMessage(this._model.newChatMessage(chatMessageShim));
		return this;
	}

	_handleAskChat(_$element) {
		const message = _$element?.val();
		if (message) {
			this._shim.getLeadWithCallback(() => {
				this._eventHandler.trigger(SEND_MESSAGE_EVENT_TYPE, message)
				_$element.val('');
			});
		}
	}

	_handleSendMessage(message) {
		message = message || this._$sendChatTextbox.val();
		if (message) {
			this._eventHandler.trigger(SEND_MESSAGE_EVENT_TYPE, message)
			this._$sendChatTextbox.val('');
		}
		return this;
	}

	_initializeHtmlElements() {
		this._$toggleChatButton = $('#MobileChatBtn');
		this._$chatContainer = $('#ChatContainer');
		this._$sendChatButton = $('#ChatSendButton');
		this._$sendChatTextbox = $('#ChatInput');
		this._$askChat = $('#AskChat');
		this._$modalAskChat = $('#ModalAskChat');
		this._$askChatButton = $('#AskChatButton');
		this._$modalAskChatButton = $('#ModalAskChatButton');
		return this;
	}

	_initializeSendChatForm() {
		this._$sendChatTextbox.on('keyup', (evt) => {
			if (13 === evt.which) {
				this._handleSendMessage();
			}
		}).on('input', () => {
			this._$sendChatButton.prop('disabled', !this._$sendChatTextbox.val());
		})

		this._$sendChatButton.on('click', () => {
			this._handleSendMessage();
		});

		this._$askChat.on('keyup', (evt) => {
			if (13 === evt.which) {
				this._handleAskChat(this._$askChat);
			}
		});

		this._$askChatButton.on('click', () => {
			this._handleAskChat(this._$askChat);
		});

		this._$modalAskChat.on('keyup', (evt) => {
			if (13 === evt.which) {
				this._handleAskChat(this._$modalAskChat);
			}
		});

		this._$modalAskChatButton.on('click', () => {
			this._handleAskChat(this._$modalAskChat);
		});

		return this;
	}
}

export { ChatView }
